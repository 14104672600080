"use client";

import { useTrackJobAdInview } from "./hooks/use-track-job-ad-inview.hook";
import type { JobModel } from "./models/job.model";
import { Link } from "@/components/Link/Link.component";
import clsx from "clsx";
import NextImage from "next/image";
type JobListItemProps = {
  readonly className?: string;
  readonly id: number;
  readonly image: JobModel.Image | null;
  readonly headline: string;
  readonly placement: string;
  readonly targetUrl: string;
};
export function JobListItem({
  className,
  headline,
  id,
  image,
  placement,
  targetUrl
}: JobListItemProps) {
  const ref = useTrackJobAdInview({
    jobAdId: id,
    placement
  });
  return <Link className={clsx(["my-2 block", className])} href={targetUrl} openInNewTab ref={ref} rel="noreferrer" data-sentry-element="Link" data-sentry-component="JobListItem" data-sentry-source-file="JobListItem.component.tsx">
      {image ? <figure className="relative block h-[60px] max-w-[160px]">
          <NextImage alt={image.alternativeText} className="h-[60px] w-full object-contain object-left" height="60" src={image.thumbnailUrl} width="160" />
        </figure> : null}

      <p className="text-base leading-snug">{headline}</p>
    </Link>;
}